<template>
  <div
    v-if="(!cartMode && isLoading === false) || (cartMode && products.length)"
  >
    <div :class="mode + (count ? ' count-' + count : '')" :data-count="count">
      <template
        v-if="
          !products.length && (currentSort || categoriesFilter) && !cartMode
        "
      >
        <div class="products-list-filter-and-sort-empty">
          {{ $t("messages.filterAndSortNoResult") }}
        </div>
      </template>
      <template v-else-if="mode === 'products-slider'">
        <Slider
          :products="products"
          :categoryid="categoryid"
          :showaddtocart="showaddtocart"
          :addToCartLabel="addToCartLabel"
          :showprocessorder="showprocessorder"
          :processordertext="processordertext"
          :get-default-choice="getDefaultChoice"
          :fill-product-with-choice="fillProductWithChoice"
          :showratingstar="showratingstar"
          :is-product-list-from-parent="isChildComponent"
        >
        </Slider>
      </template>
      <template v-else-if="mode === 'products-slider-cart'">
        <SliderCart
          :products="products"
          :categoryid="categoryid"
          :showaddtocart="showaddtocart"
          :addToCartLabel="addToCartLabel"
          :showprocessorder="showprocessorder"
          :processordertext="processordertext"
          :get-default-choice="getDefaultChoice"
          :fill-product-with-choice="fillProductWithChoice"
        >
        </SliderCart>
      </template>
      <template v-else-if="mode === 'products-grid'">
        <Grid
          :products="products"
          :categoryid="categoryid"
          :showaddtocart="showaddtocart"
          :addToCartLabel="addToCartLabel"
          :showprocessorder="showprocessorder"
          :processordertext="processordertext"
          :get-default-choice="getDefaultChoice"
          :fill-product-with-choice="fillProductWithChoice"
          :showratingstar="showratingstar"
          :is-product-list-from-parent="isChildComponent"
        >
        </Grid>
      </template>
      <template v-else-if="mode === 'products-grid-cart'">
        <GridCart
          :products="products"
          :categoryid="categoryid"
          :showaddtocart="showaddtocart"
          :addToCartLabel="addToCartLabel"
          :showprocessorder="showprocessorder"
          :processordertext="processordertext"
          :get-default-choice="getDefaultChoice"
          :fill-product-with-choice="fillProductWithChoice"
        >
        </GridCart>
      </template>
      <template v-else-if="mode === 'products-grid-vp'">
        <GridVP
          :products="products"
          :columnNumber="columnnumber"
          :textInButton="textinbutton"
          :is-product-list-from-parent="isChildComponent"
        >
        </GridVP>
      </template>
      <template v-else-if="mode === 'products-list'">
        <List
          :products="products"
          :categoryid="categoryid"
          :showaddtocart="showaddtocart"
          :addToCartLabel="addToCartLabel"
          :showprocessorder="showprocessorder"
          :processordertext="processordertext"
          :get-default-choice="getDefaultChoice"
          :fill-product-with-choice="fillProductWithChoice"
          :showratingstar="showratingstar"
          :is-product-list-from-parent="isChildComponent"
        >
        </List>
      </template>
      <template v-else-if="mode === 'products-list-cart'">
        <ListCart
          :products="products"
          :categoryid="categoryid"
          :showaddtocart="showaddtocart"
          :addToCartLabel="addToCartLabel"
          :showprocessorder="showprocessorder"
          :processordertext="processordertext"
          :get-default-choice="getDefaultChoice"
          :fill-product-with-choice="fillProductWithChoice"
        >
        </ListCart>
      </template>
      <template v-else-if="mode === 'products-list-direct-buy'">
        <ListDirectBuy :products="products" :columnFormat="columnFormat">
        </ListDirectBuy>
      </template>
    </div>
    <div ref="endList" class="endList" v-if="!cartMode">
      <Pagination
        :currentPage="currentPage"
        :nextPageIsLoading="nextPageIsLoading"
        :totalPages="totalPages"
        :errorPagination="errorPagination"
        :paginationMode="paginationMode"
      />
    </div>
  </div>
  <div v-else-if="!cartMode" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import AddToCart from "@/components/AddToCart.vue";
import Grid from "@/components/productlist/types/Grid.vue";
import GridCart from "@/components/productlist/types/GridCart.vue";
import GridVP from "@/components/productlist/types/GridVP.vue";
import List from "@/components/productlist/types/List.vue";
import ListCart from "@/components/productlist/types/ListCart.vue";
import ListDirectBuy from "@/components/productlist/types/ListDirectBuy.vue";
import Slider from "@/components/productlist/types/Slider.vue";
import SliderCart from "@/components/productlist/types/SliderCart.vue";
import Pg from "@/services/pg";
import { useTrackingStore } from "@/store/tracking";
import AlreadyOrderedProducts from "@/services/alreadyOrderedProducts";
import Utils from "@/services/utils";
import ProductService from "@/services/product";
import PaginationService from "@/services/pagination";
import Pagination from "@/components/productlist/subcomponents/Pagination.vue";

export default {
  name: "ProductList",
  props: {
    categoryid: String,
    mode: String,
    count: Number,
    showaddtocart: Boolean,
    addToCartLabel: String,
    showprocessorder: {
      type: Boolean,
      default: false,
    },
    processordertext: String,
    postMountedProducts: Array,
    cartMode: false,
    showratingstar: Boolean,
    columnnumber: String,
    textinbutton: String,
    columnFormat: String,
  },
  components: {
    Pagination,
    AddToCart,
    Grid,
    GridCart,
    GridVP,
    List,
    ListCart,
    ListDirectBuy,
    Slider,
    SliderCart,
  },
  data() {
    return {
      products: [], // final products to display
      isChildComponent: false,
      isLoading: false,
      productsTmp: [], // used to store products before filtering
      observerEndList: null,
      currentPage: 1,
      lastPageDownload: 1,
      nextPageIsLoading: false,
      totalPages: 1,
      errorPagination: false,
      paginationMode: "auto-lazy",
      disabledDefaultPagination: false,
      currentSort: null,
      categoriesFilter: "",
    };
  },
  computed: {
    currentCategoryId() {
      return this.categoryid || window.category.id;
    },
  },
  watch: {
    postMountedProducts: function (products) {
      this.products = products;
      this.isChildComponent = true;
    },
  },
  async mounted() {
    if (["extrasell", "extraproducts"].includes(this.categoryid)) {
      return;
    }

    if (this.shouldHideProducts()) {
      this.isLoading = true;
    }

    this.totalPages =
      window.productList && window.productList[this.currentCategoryId]
        ? window.productList[this.currentCategoryId].totalPages
        : 0;
    console.log(this.totalPages);
    if (
      !this.cartMode &&
      this.totalPages > 1 &&
      this.paginationMode === "auto-lazy"
    ) {
      // IntersectionObserver to load more products when scrolling to endList element is in the viewport
      // When is trigger the onEndList method is called
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.observerEndList = new IntersectionObserver(this.onEndList);
      if (this.$refs.endList) {
        // Start observing endList element in the dom
        this.observerEndList.observe(this.$refs.endList);
      }
    }

    const selectorSearchExist = document.querySelector('div[data-app="sort"]');
    if (selectorSearchExist) {
      window.addEventListener("pageSorted", this.onNewPageSorted);
      window.addEventListener("pageSortedLoading", this.onLoadPageSorted);
    }

    await this.manageProducts();
    console.log("productlistproducts", this.products);
  },
  unmounted() {
    if (this.observerEndList) {
      this.observerEndList.disconnect();
    }
    window.removeEventListener("pageSorted", this.onNewPageSorted);
    window.removeEventListener("pageSortedLoading", this.onLoadPageSorted);
  },
  methods: {
    onEndList(entries) {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting && !this.nextPageIsLoading) {
          this.currentPage = this.currentPage + 1;
          if (
            this.currentPage <= this.totalPages &&
            this.currentPage > this.lastPageDownload
          ) {
            this.lastPageDownload = this.currentPage;
            this.nextPageIsLoading = true;
            if (this.disabledDefaultPagination) {
              window.dispatchEvent(
                new CustomEvent("getNewPageSorted", {
                  detail: { page: this.currentPage },
                }),
              );
            } else {
              await this.getNextPageStatic();
              this.reloadRatingStar();
            }
          } else if (this.currentPage > this.totalPages) {
            this.observerEndList.unobserve(this.$refs.endList);
          }
        }
      });
    },
    onLoadPageSorted(event) {
      if (
        event.detail.identifier &&
        event.detail.identifier !== this.currentCategoryId
      ) {
        return;
      }
      this.observerEndList?.unobserve(this.$refs.endList);
      this.isLoading = event.detail.loading;
    },
    onNewPageSorted(event) {
      if (
        event.detail.identifier &&
        event.detail.identifier !== this.currentCategoryId
      ) {
        return;
      }

      const productsPerPage = window.globalConfig?.productsPerPage || 12;
      this.disabledDefaultPagination = true;
      this.isLoading = false;
      this.lastPageDownload = event.detail.page;
      this.nextPageIsLoading = false;
      this.totalPages = Math.ceil(event.detail.total / productsPerPage);
      if (
        this.currentSort !== event.detail.sort.name ||
        this.categoriesFilter !== event.detail.categories
      ) {
        this.currentSort = event.detail.sort.name;
        this.categoriesFilter = event.detail.categories;
        this.currentPage = event.detail.page;
        this.products = [];
        if (this.totalPages > 1) {
          this.$nextTick(() => {
            this.observerEndList.observe(this.$refs.endList);
          });
        }
      }
      const products = event.detail.data;
      products.forEach(ProductService.mappingProduct);
      this.products = this.products.concat(products);
      this.$nextTick(() => {
        this.reloadRatingStar();
      });
      this.observerEndList = new IntersectionObserver(this.onEndList);
    },
    reloadRatingStar() {
      if (this.showratingstar && window.globalConfig.scriptAvisVerifies) {
        SkeepersProductWidget.generate();
      }
    },
    async manageProducts() {
      if (
        this.currentCategoryId &&
        window.productList &&
        window.productList[this.currentCategoryId]
      ) {
        this.setProductsTmp(this.currentCategoryId);

        if (this.shouldHideProducts()) {
          await this.hideAlreadyOrderedProducts();
          this.isLoading = false;
        } else {
          this.products = this.orderProductsByPosition(this.productsTmp);
        }
      }
    },
    setProductsTmp(categoryId) {
      const categoryItems = window.productList[categoryId].products;
      if (this.count) {
        this.productsTmp = categoryItems.slice(0, this.count);
      } else {
        this.productsTmp = categoryItems;
      }
    },
    getDefaultChoice(listItem) {
      const defaultChoice = listItem.choices.find(
        (item) => item.default && item.show,
      );
      return defaultChoice
        ? defaultChoice
        : listItem.choices.find((item) => item.show);
    },
    fillProductWithChoice(product) {
      if (product.choice) {
        return product;
      }

      product.choice = this.getDefaultChoice(product);
      return product;
    },
    displayDuration(duration, startingPrice = false) {
      return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
    },
    shouldHideProducts() {
      return window.globalConfig?.hideAlreadyOrderedProducts === true;
    },
    async hideAlreadyOrderedProducts() {
      try {
        const affiliateId = window.globalConfig.affiliateId;

        const contactPolarisId =
          useTrackingStore().urlParams?.sfId ||
          JSON.parse(Utils.getCookie("atlasData"))?.sfId;

        if (AlreadyOrderedProducts.getAlreadyOrderedProducts()?.length > 0) {
          const productsAlreadyOrdered =
            AlreadyOrderedProducts.getAlreadyOrderedProducts();
          this.filterProductsAlreadyOrdered(productsAlreadyOrdered);
          return;
        }

        if (!affiliateId || !contactPolarisId) {
          this.products = this.orderProductsByPosition(this.productsTmp);
          return;
        }

        const productsAlreadyOrdered = await Pg.getActiveProducts({
          contactPolarisId: contactPolarisId,
          affiliateId: affiliateId,
        });

        if (!productsAlreadyOrdered?.length) {
          this.products = this.orderProductsByPosition(this.productsTmp);
          return;
        }

        this.filterProductsAlreadyOrdered(productsAlreadyOrdered);

        AlreadyOrderedProducts.setAlreadyOrderedProducts(
          productsAlreadyOrdered,
        );
      } catch (e) {
        this.products = this.orderProductsByPosition(this.productsTmp);
        console.error(e);
      }
    },
    filterProductsAlreadyOrdered(productsAlreadyOrdered) {
      const productsTemp = this.productsTmp.filter((productList) => {
        if (!productsAlreadyOrdered.includes(productList.salesforceId)) {
          return productList;
        }
      });
      this.products = this.orderProductsByPosition(productsTemp);
    },
    orderProductsByPosition(products) {
      return products.sort((a, b) => {
        const aPositionDefined = !isNaN(a.position) && a.position !== null;
        const bPositionDefined = !isNaN(b.position) && b.position !== null;

        if (!aPositionDefined && !bPositionDefined)
          return a.name.localeCompare(b.name);
        if (!aPositionDefined && bPositionDefined) return 1;
        if (aPositionDefined && !bPositionDefined) return -1;
        if (a.position !== b.position) return a.position - b.position;
        return a.name.localeCompare(b.name);
      });
    },
    async getNextPageStatic() {
      try {
        const slug = window.productList[this.currentCategoryId].categorySlug;
        const newPage = await PaginationService.getPage(
          slug,
          this.currentPage,
          this.paginationMode,
        );
        if (newPage?.[this.currentCategoryId]?.products) {
          this.productsTmp = this.productsTmp.concat(
            newPage[this.currentCategoryId].products,
          );
          this.products = this.productsTmp;
        }
      } catch (e) {
        this.currentPage = this.totalPages;
        this.lastPageDownload = this.totalPages;
        this.errorPagination = true;
        this.observerEndList.disconnect();
        this.observerEndList = null;
      }
      this.nextPageIsLoading = false;
    },
  },
};
</script>
